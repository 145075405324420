<template>
    <div class="container">
        <Y-header  font-color="#FFFFFF" search-color="aliceblue"></Y-header>
        <div class="body-container">
            <div class="product-tab-box" @mouseleave="mouseleave" :style="{height:productTabHeight+'px',opacity}" ref="scrollBox">
                <div class="product-item"  v-for="(item,i) in productData" :key="i" @click="productTabSwitch(item,i)">
                    <img :class="[productActive==i?'active':'']" :src="item.coverImg?item.coverImg[0].url:defaultImg"/>
                    <span class="product-name">{{item.name}}</span>
                </div>
            </div>
            <banner class="banner" :data="bannerData" height="600px" :speed="bannerSpeed"></banner>

            <div class="introduce-container Waistline">
                <center-title text="三大核心特点，构建智慧物联网" bold font-size="42" position="center"></center-title>
                <center-title text="围绕企业定制开发服务的数字化管理和设备互联，构建智慧产品服务" bold font-size="20" color="#808080" position="center"></center-title>
                <div class="content">
                    <p class="middle-text">全流程定制开发呈现更适合使用的智慧场景</p>
                    <div class="point-item" :style="{top:item.top+'px',left:item.left+'px'}" v-for="(item,i) in cardData" :key="i" v-if="i<3">
                        <div class="title">
                            <img class="icon" :src="item.icon"/>
                            <span>{{item.characteristic}}</span>
                        </div>
                        <div class="content-introduce">
                            <p>{{item.introduce}}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="function-introduce-container">
                <center-title text="定制级设备功能" bold font-size="24" position="center"></center-title>
                <center-title text="提供终端设备各项功能，一端互链实时执行指令" bold font-size="20" color="#808080" position="center"></center-title>

                <div class="function-tab-box">
                    <div class="function-item" :class="[active == i ? 'active':'']" v-for="(item,i) in sceneData" :key="i" @click="switchFunction(item,i)" v-if="i<4">
                        <img class="function-icon" :src="functionIcons[i]"/>
                        <p class="function-name">{{item.name}}</p>
                    </div>
                </div>
                <div class="desc">
                    <div class="item">
                        <div class="cover">
                            <img v-prlx="{  custom: true,limit: { min: 50, max: 100 } }" v-if="i==0" v-for="(item,i) in functionData.img"  :src="item.url"/>
                        </div>
                        <div class="text-box">
                            <div class="text">
                                <h2>{{functionData.name}}</h2>
                            </div>
                            <div class="text" v-html="functionData.func">
                                <p>{{functionData.func}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Y-footer></Y-footer>
        <a-back-top/>
    </div>
</template>

<script>
import banner from "@/components/banner.vue";
import centerTitle from "@/components/centerTitle.vue";
import footer from "@/components/footer.vue";
import header from "@/components/header.vue";
import handleRequest from "@/utils/handleRequest";
import {bannerData, selectProduct} from "@/api/apis";
import tools from "@/utils/tools"
export default {
    components: {
        'Y-footer':footer,
        'Y-header':header,
        banner,
        centerTitle
    },
    data() {
        return {
            defaultImg:require('@/assets/功能4.png'),
            pointPos:[
                {
                    top:'0',
                    left:'115',
                },
                {
                    top:'0',
                    left:'1180',
                },
                {
                    top:'415',
                    left:'630',
                },
            ],
            bannerData:[],
            cardLoading:true,
            productData:[],
            currentProductData:{},
            cardData:[],
            functionData:{},
            sceneData:[],
            active:0,
            bannerSpeed:2000,
            productTabHeight:'',
            opacity:'',
            productType:'',
            footerJumpId:'',
            id:'',
            functionIcons:[
                require('@/assets/功能1.png'),
                require('@/assets/功能2.png'),
                require('@/assets/功能3.png'),
                require('@/assets/功能4.png'),
            ],
            productActive:0,
        }
    },
    watch: {
        productShow(newValue, oldValue) {
            if(newValue){
                this.productTabHeight = 400
                this.opacity = 1
            }else{
                this.productTabHeight = 0
                this.opacity = 0.2
            }
        },
        footerJumpProductId(n){
            if(n){
                this.productData.forEach(item=>{
                    if(item.id == n){
                        this.cardData = item.introduce
                        this.addPropertyToCardData()
                        this.sceneData = item.scenario
                        this.functionData = this.sceneData[0]
                        this.functionData.func = this.clearRichText(this.functionData.func)
                        this.productType = this.productData[0].name
                        this.getBannerData()
                    }
                })
            }
        },
    },
    computed:{
        productShow(){
            return this.$store.state.productShow
        },
        footerJumpProductId(){
            return this.$store.state.footerSetCurProductId
        },
    },
    mounted() {
        this.id = this.$route.query.id
        this.getProductData();
        this.makeScroll();
    },
    methods:{
        homeJumpShowProduct(){
            if(this.id){
                this.productData.forEach(item=>{
                    if(item.id == this.id){
                        this.cardData = item.introduce
                        this.addPropertyToCardData()
                        this.sceneData = item.scenario
                        this.functionData = this.sceneData[0]
                        this.functionData.func = this.clearRichText(this.functionData.func)
                        this.productType = this.productData[0].name
                        this.getBannerData()
                    }
                })
            }
        },
        addPropertyToCardData(){
            let icon = [
                require('@/assets/特点1@2x.png'),
                require('@/assets/特点2@2x.png'),
                require('@/assets/特点3@2x.png'),
            ]
            this.cardData.forEach((item,i)=>{
                item.icon = icon[i]
                item.top = this.pointPos[i].top
                item.left = this.pointPos[i].left
            })
        },
        clearRichText(texts){
            if(texts){
                let text1 = texts.replace(/<\/?(img|table)[^>]*>/g,"")
                let text2 = text1.replace(/<\/?.+?>/g,"")
                let text3 = text2.replace(/[ | ]*\n/g,"")
                let text4 = text3.replace(/ /g,"")
                return text4
            }

        },
        changeFunctionData(){
            if(this.sceneData.length>2){
                setInterval(()=>{
                    this.active ++
                    if(this.active == this.sceneData.length){
                        this.active = 0
                    }
                    this.functionData = this.sceneData[this.active]
                },4000)
            }
        },
        mouseleave(){
            let currentRoute = '/product'
            if(this.$route.path == currentRoute){
                this.$store.commit('setProductShow',false)
            }
        },
        productTabSwitch(item,i){
            console.log(item)
            this.productActive = i
            this.currentProductData = item
            this.cardData = this.currentProductData.introduce
            let icon = [
                require('@/assets/特点1@2x.png'),
                require('@/assets/特点2@2x.png'),
                require('@/assets/特点3@2x.png'),
            ]
            this.cardData.forEach((item,i)=>{
                item.icon = icon[i]
                item.top = this.pointPos[i].top
                item.left = this.pointPos[i].left
            })
            this.productType = item.name
            this.sceneData = item.scenario
            this.functionData = this.sceneData[0]
            this.functionData.func = this.clearRichText(this.functionData.func)
            this.getBannerData()
        },
        switchFunction(item,i){
            this.active = i
            console.log(item)
            item.func = this.clearRichText(item.func)
            this.functionData = item
        },
        makeScroll(){
            let flag = false
            let scrollLeft
            let downX
            this.$refs['scrollBox'].addEventListener('mousedown',(e)=>{
                flag = true
                downX = e.clientX
                scrollLeft = this.$refs['scrollBox'].scrollLeft
                this.$refs['scrollBox'].addEventListener('mousemove',(event)=>{
                    if(flag) {
                        let moveX = event.clientX
                        let scrollX = moveX - downX
                        if(scrollX < 0 && scrollLeft > 0){
                            this.$refs.scrollBox.scrollLeft = scrollLeft - scrollX
                        }else{
                            this.$refs.scrollBox.scrollLeft = scrollLeft - scrollX
                        }

                    }
                })
                this.$refs['scrollBox'].addEventListener('mouseup',()=>{
                    flag = false
                })
            })
        },
        async getProductData(){
            const data = handleRequest(await selectProduct({})).content
            data.forEach(item=>{
                item.introduce = JSON.parse(item.introduce)
                item.scenario = JSON.parse(item.scenario)
                if(item.coverImg){
                    item.coverImg = JSON.parse(item.coverImg)
                }
            })
            this.productData = data
            this.currentProductData = this.productData[0]
            this.cardData = this.currentProductData.introduce
            this.addPropertyToCardData()
            console.log(this.productData)
            this.sceneData = this.productData[0].scenario
            // console.log(this.sceneData)
            this.functionData = this.sceneData[0]
            this.functionData.func = this.clearRichText(this.functionData.func)
            this.productType = this.productData[0].name
            this.getBannerData();
            this.homeJumpShowProduct();
            this.changeFunctionData();

        },
        async getBannerData(){
            const data = handleRequest(await bannerData({type:'产品服务',prodType:this.productType,state:1}))
            let banner = data.content
            banner.forEach(item=>{
                item.img = JSON.parse(item.img)[0]

            })
            this.bannerSpeed = (banner[0]?.second*1000) || this.bannerSpeed
            this.bannerData = banner
            // console.log(banner)

        },
    },

}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1859px){
    .introduce-container{
        .content{
            overflow: hidden;
            margin-top: 20px;
            position: unset !important;
            display: flex;
            justify-content: space-evenly;
            height: auto !important;
            margin-bottom: 40px;
            width: 100%;
            background-image: unset !important;
            background-size: 30%;
            background-repeat: no-repeat;
            background-position-x: 50%;
        }
        .middle-text{
            display: none;
            width: 154px;
            height: 132px;
            font-size: 15px !important;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 44px;
            position: absolute;
            text-align: center;
            top: 40px !important;
            left:calc(50% - 154px /2)
        }
        .point-item{
            position: unset !important;
            width: 320px;
            font-size: 14px !important;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #505050;
            line-height: 34px;
            .title{
                .icon{
                    width: 58px;
                }
            }
        }
    }

}
.content-introduce{
    padding: 10px;
    overflow: hidden;
    height: 390px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 11;
    text-overflow: ellipsis;

}
.Waistline{
    margin: 0 160px;
}
.container{
    .body-container{
        display: block;
        //margin:0 160px;
        background-color: #FFFFFF;

        .product-tab-box{
            transition: all 1s ease-in-out;
            box-sizing: border-box;
            overflow-y: hidden;
            &::-webkit-scrollbar{
                height: 0px;
            }
            margin: 0 160px;
            height: 0px;
            display: flex;
            .active{
                box-shadow: 0 0 4px 3px #409EFF;
            }
            .product-item{
                padding: 40px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                transition: all .3s;
                user-select: none;
                &:hover{
                    transform: scale(1.1);
                }
                img{
                    object-fit: cover;
                    -webkit-user-drag: none;
                    width: 248px;
                    height: 150px;
                    border-radius: 8px;
                }
                .product-name{
                    margin-top: 30px;
                    font-size: 18px;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 400;
                    color: #1F1F1F;
                    line-height: 18px;
                }
            }
        }

        .introduce-container{
            .content{
                overflow: hidden;
                margin-top: 20px;
                position: relative;
                display: flex;
                min-height: 600px;
                width: 100%;
                background-image: url("../assets/编组@2x.png");
                background-size: 30%;
                background-repeat: no-repeat;
                background-position-x: 50%;
            }
                .middle-text{
                    width: 154px;
                    height: 132px;
                    font-size: 22px;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    line-height: 44px;
                    position: absolute;
                    text-align: center;
                    top: 70px;
                    left:calc(50% - 154px /2)
                }
            .point-item{
                position: absolute;
                width: 320px;
                font-size: 16px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                color: #505050;
                line-height: 34px;
                .title{
                    .icon{
                        width: 58px;
                    }
                }
            }
        }
        .function-introduce-container{
            background-color: #f2f7ff;
            box-sizing: border-box;
            .active{
                border-bottom: 2px solid #3769FE !important;
            }
            .function-tab-box{
                margin: 0 320px;
                display: flex;
                border-bottom: 1px solid #d7d7d7;
                .function-item{
                    box-sizing: border-box;
                    width: 25%;
                    margin: 0 10px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    .function-icon{
                        width: 200px;
                    }
                    &:hover{
                        border-bottom: 2px solid #3769FE;
                    }
                    img{
                        width: 100%;
                        border-radius: 8px;
                        object-fit: cover;
                        height: 138px;
                    }
                    .function-name{
                        text-align: center;
                        width: 220px;
                        font-size: 20px;
                        font-family: PingFangSC, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                        line-height: 28px;
                        padding: 10px;
                        margin: 0;
                    }
                }

            }
            .desc{
                margin: 0 320px;
            }
            .item{
                display: flex;
                margin: 20px 0;
                .cover{
                    img{
                        transform: scale(calc(var(--parallax-value) / 50 ));
                        object-fit: cover;
                        width: 360px;
                        height: 230px;
                    }
                }
                .text-box{
                    min-height: 260px;
                    margin-left: 30px;
                    display: flex;
                    flex-direction: column;
                    h3{
                        font-weight: 550;
                    }
                }
            }
        }
        .scene-box{
            display: flex;
            justify-content: space-between;
            .scene-item{
                .scene-cover{
                    object-fit: cover;
                    width: 160px;
                    height: 160px;
                }
                p{
                    font-weight: 550;
                    font-size: 18px;
                    text-align: center;
                    padding: 20px;
                }
            }
        }
    }
}
</style>
